<script lang="ts">
import _ from 'lodash-es';
import useDialogStore from '@/stores/dialog';

export default {
  setup() {
    const dialogStore = useDialogStore();
    const globalDialog = computed(() => dialogStore.globalDialog);

    const debounceFunc = _.debounce(() => (globalDialog.value.func !== null
      ? globalDialog.value.func()
      : () => {}), 1000, { leading: true, trailing: false });

    const debounceFuncCancel = _.debounce(() => (globalDialog.value.func_cancel !== null
      ? globalDialog.value.func_cancel()
      : () => {}), 1000, { leading: true, trailing: false });

    const handleClose = _.debounce(() => {
      if (globalDialog.value.close !== null)
        globalDialog.value.close();

      dialogStore.closeDialog();
    }, 1000, { leading: true, trailing: false });

    return {
      globalDialog,
      handleClose,
      debounceFunc,
      debounceFuncCancel
    };
  },
};
</script>

<template>
  <el-dialog
    v-model="globalDialog.status" class="ma-dialog" data-cy="ma-dialog" :title="globalDialog.title"
    :width="366" :before-close="handleClose" :show-close="!globalDialog.persistent"
    :close-on-click-modal="!globalDialog.persistent"
  >
    <div v-if="(typeof globalDialog.text !== 'object')" v-html="globalDialog.text" />
    <component :is="globalDialog.text ?? 'span'" v-else />
    <template #footer>
      <div class="dialog-footer" :class="{ 'dialog-footer--2btn': globalDialog.func !== null }">
        <el-button
          v-if="!globalDialog.persistent && !globalDialog.onlyConfirm && globalDialog.func_cancel === null" data-cy="dialog-cancel" size="large"
          @click="handleClose"
        >
          {{ globalDialog.func !== null ? '取消' : '關閉' }}
        </el-button>
        <el-button
          v-if="globalDialog.func_cancel !== null" data-cy="dialog-cancel" size="large"
          @click="debounceFuncCancel()"
        >
          {{ globalDialog.btnCancelText }}
        </el-button>
        <el-button
          v-if="globalDialog.func !== null" data-cy="dialog-confirm" size="large" type="primary"
          @click="debounceFunc()"
        >
          {{ globalDialog.btnText }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;

  .el-button {
    flex: 1 1 155px;
    max-width: 155px;
    font-weight: bold;

    + .el-button {
      margin-left: 15px;
    }
  }
}
</style>
